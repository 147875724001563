<template>
  <ExternalLinkWarning
    class="message-body"
    :external-link-warning="externalLinkWarning"
  >
    <div
      class="message-body__content"
      ref="content"
      v-test:body
      @click="onMessageBodyClicked"
    />
    <div class="message-body__attachments">
      <DownloadLink
        class="message-body__attachment button button--small"
        v-for="file in attachments"
        show-progress
        :key="file.id"
        :url="file.url"
        :download="file.filename"
        :title="file.filename"
      >
        <Icon symbol="file" />
        <span>
          {{ file.filename }}
          ({{ file.size | formatDataSize }})
        </span>
      </DownloadLink>
    </div>
  </ExternalLinkWarning>
</template>

<script>
  import ExternalLinkWarning from '@/components/ExternalLinkWarning/ExternalLinkWarning';
  import Icon from '@/components/Icon/Icon';
  import escapeHtml from '@/lib/escapeHtml';
  import formatDataSize from '@/lib/formatDataSize';
  import truncateMiddle from '@/lib/truncateMiddle';
  import DownloadLink from '@/components/DownloadLink';

  const colorMidnight = '#202945';

  // We have to add darkMode styles here, because the email body might be wrapped in a shadowRoot,
  // in which case the global darkMode styles will not be available here.
  const darkModeStyles = `<style>
    @media not print {
      img {
        filter: invert(1) hue-rotate(180deg);
      }
    }
  </style>`;

  const styleSheet = `<style>
  @media print {
    #sm-email-viewer p {
      page-break-inside: avoid;
    }
    #sm-email-viewer h1, #sm-email-viewer h2, #sm-email-viewer h3, #sm-email-viewer h4, #sm-email-viewer h5, #sm-email-viewer h6 {
      page-break-after: avoid;
    }
    #sm-email-viewer ul, #sm-email-viewer img {
      page-break-inside: avoid;
    }
  }

  /* styling for replied messages */
  #sm-email-viewer blockquote {
    padding-left: 8px;
    margin: 8px 8px 8px 4px;
    border-left: 2px solid #5856d6;
    color: #5856d6;
  }
  #sm-email-viewer blockquote blockquote {
    border-color: #03afcd;
    color: #03afcd;
  }
  #sm-email-viewer blockquote blockquote blockquote {
    border-color: #17c00d;
    color: #17c00d;
  }

  @media print {
    #sm-email-viewer blockquote {
      border-color: ${colorMidnight};
      color: ${colorMidnight};
    }
    #sm-email-viewer blockquote blockquote {
      border-color: ${colorMidnight};
      color: ${colorMidnight};
    }
    #sm-email-viewer blockquote blockquote blockquote {
      border-color: ${colorMidnight};
      color: ${colorMidnight};
    }
  }

  #sm-email-viewer img[data-src] {
    background: #e8e8e8;
  }

  #sm-email-viewer img[data-src][data-show] {
    background-image: url(${require('@/assets/img/message-loading.svg')})
  }

  #sm-email-viewer pre {
    white-space: pre-wrap;
  }

  #sm-email-viewer .attachment::before {
    background-image: url(${require('@/assets/img/attach.svg')});
    background-size: cover;
    content: '';
    display: inline-block;
    height: 16px;
    margin-right: 0.25rem;
    width: 16px;
  }
</style>`;

  const dummySrc =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

  export default {
    components: {
      DownloadLink,
      ExternalLinkWarning,
      Icon,
    },
    filters: {
      formatDataSize,
      truncateMiddle,
    },
    props: {
      body: {
        type: String,
        required: true,
        default: '',
      },
      attachments: {
        type: Array,
        required: true,
      },
      showExternalImages: {
        type: Boolean,
        required: false,
        default: false,
      },
      elementContainerClass: {
        type: String,
        required: true,
      },
      externalLinkWarning: {
        type: Boolean,
        default: false,
      },
      darkMode: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        hasExternalImages: false,
        shadowDomSupported: false,
      };
    },
    watch: {
      body(newValue) {
        this.handleBodyUpdate(newValue);
        this.scaleContent();
      },
      showExternalImages(newValue) {
        this.updateExternalImages(this.shadowDOM, newValue);
        this.scaleContent();
      },
      hasExternalImages(newValue) {
        this.$emit('hasExternalImages', newValue);
      },
    },
    mounted() {
      this.shadowDomSupported = this.$refs.content.attachShadow;
      this.shadowDOM = this.shadowDomSupported
        ? this.$refs.content.attachShadow({ mode: 'open' })
        : this.$refs.content;

      this.handleBodyUpdate(this.body);
      this.scaleContent();
    },
    methods: {
      checkHasExternalImages(dom) {
        const images = this.getExternalImages(dom);

        const cssBackgrounds = Array.from(this.getStyleBlocks(dom)).some((el) =>
          el.textContent.toLowerCase().includes('sm-background-image')
        );

        return images.length > 0 || cssBackgrounds;
      },
      getExternalImages(dom) {
        return dom.querySelectorAll('img[data-src]');
      },
      getStyleBlocks(dom) {
        return dom.querySelectorAll('style');
      },
      getElementsWithStyleAttributes(dom) {
        return dom.querySelectorAll('[style]');
      },
      handleBodyUpdate(body) {
        const html = `
        ${this.darkMode ? darkModeStyles : ''}
        ${styleSheet}
        <div id="sm-email-viewer" class="${this.elementContainerClass}">
          ${body.replace(/<([/]?)sm-style(.*?)>/g, '<$1style$2>')}
        </div>
      `;
        const fragment = document.createRange().createContextualFragment(html);

        this.updateExternalImages(fragment, this.showExternalImages);
        this.hasExternalImages = this.checkHasExternalImages(fragment);

        this.linkUrls(fragment);

        // NOTE: the message body is injected into the DOM without any sanitization.
        // and therefor fulnerable to XSS and injection attacks. The HTML should have
        // been sanitized by the API.
        this.shadowDOM.innerHTML = '';
        this.shadowDOM.appendChild(fragment);
      },
      async scaleContent() {
        // If the content is larger than can fit on the screen,
        // scale the content container and recalculate the height of the body.
        // Don't scale smaller than 0.5
        this.$el.removeAttribute('style');
        this.$refs.content.removeAttribute('style');

        // Wait for all images in the content to be loaded before scaling
        await Promise.all(
          Array.from(this.$refs.content.querySelectorAll('img')).map(
            (img) =>
              new Promise((resolve) => {
                if (img.complete) {
                  resolve();
                } else {
                  const image = new Image();
                  image.addEventListener('load', resolve);
                  image.addEventListener('error', resolve);
                  image.src = img.src;
                }
              })
          )
        );

        const { offsetWidth, scrollWidth } = this.$refs.content || {};
        const scale = Math.max(0.75, offsetWidth / scrollWidth);

        if (scale < 1) {
          this.$el.style.height = `${this.$el.offsetHeight * scale}px`;
          this.$refs.content.style.transform = `scale(${scale})`;
        }
      },
      updateExternalImages(dom, show) {
        const images = this.getExternalImages(dom);

        Array.from(images).forEach((img) => {
          if (show) {
            img.src = img.getAttribute('data-src');
            img.setAttribute('data-show', '');
            img.addEventListener('load', () => img.removeAttribute('data-src'));
          } else {
            img.src = dummySrc;
            img.removeAttribute('data-show');
          }
        });

        if (show) {
          const styleBlocks = this.getStyleBlocks(dom);
          const elsWithStyle = this.getElementsWithStyleAttributes(dom);

          Array.from(styleBlocks).forEach(
            (style) => (style.textContent = this.toUnsafe(style.textContent))
          );
          Array.from(elsWithStyle).forEach((el) =>
            el.setAttribute('style', this.toUnsafe(el.getAttribute('style')))
          );
        }
      },
      toUnsafe(style) {
        // Now only support background-image style. Might need to be more flexible as we discover other url() properties
        return style.replace(/sm-background-image/gi, 'background-image');
      },
      onMessageBodyClicked(ev) {
        // ev.target is not the clicked element inside shadowDOM
        // we can use ev.composedPath instead if it's supported
        const link =
          (ev.composedPath &&
            ev.composedPath().find((el) => el.tagName === 'A')) ||
          (ev.target.tagName === 'A' && ev.target.tagName);

        if (link && link.protocol === 'mailto:') {
          ev.preventDefault();
          const emailAddress = link.pathname;
          const decodedSearch = link.search && decodeURI(link.search);
          const subject =
            decodedSearch &&
            decodedSearch.match(/subject=([^&]+)/i) &&
            decodedSearch.match(/subject=([^&]+)/i)[1];
          this.$emit('mailToClicked', emailAddress, subject);
        }
      },
      linkUrls(dom) {
        // Source: https://github.com/kevva/url-regex
        const protocol = '(?:(?:[a-z]+:)?//)';
        const ip =
          '(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]\\d|\\d)(?:\\.(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]\\d|\\d)){3}';
        const host =
          '(?:(?:[a-z\\u00a1-\\uffff0-9][-_]*)*[a-z\\u00a1-\\uffff0-9]+)';
        const domain =
          '(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*';
        const tld = '(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?';
        const port = '(?::\\d{2,5})?';
        const path = '(?:[/?#][^\\s"\\)\\]]*)?';

        // When a url is wrapped in brackets or parentheses, we don't want to make those part of the link
        const wrappingChars = [
          { prefix: '[', postfix: ']' },
          { prefix: '(', postfix: ')' },
        ];
        const prefixChars = wrappingChars
          .map((obj) => `\\${obj.prefix}`)
          .join('');
        const postfixChars = wrappingChars
          .map((obj) => `\\${obj.postfix}`)
          .join('');
        const prefix = `(?:[${prefixChars}])?`;
        const postfix = `(?:[${postfixChars}])?`;

        const regex = `(${prefix})((?:(${protocol})|www\\.)(?:localhost|${ip}|${host}${domain}${tld})${port}${path})(${postfix})`;
        const urlRegex = new RegExp(regex, 'gi');

        // early exit if there are no URLs in the entire html
        if (!urlRegex.test(dom.textContent)) return;

        const getTextNodes = (textNodes, node) => {
          if (node.nodeType === Node.TEXT_NODE) {
            return [...textNodes, node];
          }
          return Array.from(node.childNodes).reduce(getTextNodes, textNodes);
        };

        // Get all textNodes that match the URL regex
        const urlTextNodes = Array.from(dom.childNodes)
          .reduce(getTextNodes, [])
          .filter((node) => urlRegex.test(node.textContent));

        // Check if there already is a anchor element as a parent of the node,
        // if so, do nothing.
        // If there isn't, replace the matched URL with an actual anchor element
        urlTextNodes.forEach((node) => {
          if (!node.parentElement.closest('a')) {
            const escapedHtml = escapeHtml(node.textContent);
            const html = escapedHtml.replace(
              urlRegex,
              (match, prefix, url, protocol, postfix) => {
                const urlWithProtocol = protocol ? url : `http://${url}`;
                // Check if there is a prefix and a matching postfix character.
                // If so, do not add those to the link
                const isWrapped =
                  prefix &&
                  wrappingChars.some(
                    (obj) => obj.prefix === prefix && obj.postfix === postfix
                  );
                return isWrapped
                  ? `${prefix}<a href="${encodeURI(
                      urlWithProtocol
                    )}" rel="noopener noreferrer" target="_blank">${url}</a>${postfix}`
                  : `<a href="${encodeURI(
                      urlWithProtocol + postfix
                    )}" rel="noopener noreferrer" target="_blank">${url}${postfix}</a>`;
              }
            );
            const fragment = document
              .createRange()
              .createContextualFragment(html);
            node.parentNode.replaceChild(fragment, node);
          }
        });
      },
    },
  };
</script>

<style src="./MessageBody.scss" lang="scss"></style>
