var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.state.loading)?_c('p',{key:("loadingMessage-" + (_vm.state.loading)),staticClass:"panel-user-message"},[_c('translate',[_vm._v("Loading message…")])],1):(_vm.state.error)?_c('ErrorView',{attrs:{"error-type":_vm.state.error}}):(_vm.activeMessage)?_c('section',{staticClass:"message-wrapper"},[_c('SwipeNavigation',{staticClass:"panel",on:{"swipeRight":function($event){return _vm.$root.$emit('readMessageInCurrentView', _vm.previousMessageId)},"swipeLeft":function($event){return _vm.$root.$emit('readMessageInCurrentView', _vm.nextMessageId)}}},[_c('Message',{attrs:{"message":_vm.activeMessage,"attachments-download-link":_vm.downloadAllFilesUrl,"show-external-images":_vm.showExternalImages,"external-link-warning":_vm.preferences.external_link_warning,"show-badge-dropdown":true,"display-formats":_vm.displayFormats,"dark-mode":_vm.darkMode},on:{"mailToClicked":_vm.onMailToClicked,"showExternalImages":_vm.onClickShowExternalImages},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"action-bar__row"},[_c('div',{staticClass:"action-bar__row-left"},[_c('div',{staticClass:"action-bar__group"},[_c('Dropdown',{directives:[{name:"test",rawName:"v-test:replyForwardDropdown",arg:"replyForwardDropdown"}],staticClass:"message__small-screen-only dropdown--align-left",scopedSlots:_vm._u([{key:"button",fn:function(ref){
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('ActionBarButton',{attrs:{"dropdown":true,"active":isOpen,"icon":"reply"},on:{"click":toggle}})]}},{key:"content",fn:function(ref){
var toggle = ref.toggle;
return [_c('ul',{staticClass:"link-list",on:{"click":toggle}},[_c('li',[_c('button',{directives:[{name:"test",rawName:"v-test:replyButton",arg:"replyButton"}],staticClass:"button button--clean link-list__item",attrs:{"type":"button"},on:{"click":_vm.replyClicked}},[_vm._v(" "+_vm._s(_vm.replyText)+" ")])]),_c('li',[_c('button',{staticClass:"button button--clean link-list__item",attrs:{"type":"button"},on:{"click":_vm.replyAllClicked}},[_vm._v(" "+_vm._s(_vm.replyToAllText)+" ")])]),_c('li',[_c('button',{directives:[{name:"test",rawName:"v-test:forwardButton",arg:"forwardButton"}],staticClass:"button button--clean link-list__item",attrs:{"type":"button"},on:{"click":_vm.forwardClicked}},[_vm._v(" "+_vm._s(_vm.forwardText)+" ")])])])]}}])}),_c('ActionBarButton',{directives:[{name:"test",rawName:"v-test:replyButton",arg:"replyButton"}],staticClass:"message__large-screen-only button--subtle button--small",attrs:{"button-text":_vm.replyText,"title":_vm.replyText,"icon":"reply"},on:{"click":_vm.replyClicked}}),_c('ActionBarButton',{staticClass:"message__large-screen-only button--subtle button--small",attrs:{"button-text":_vm.replyToAllText,"title":_vm.replyToAllText,"icon":"reply-all"},on:{"click":_vm.replyAllClicked}}),_c('ActionBarButton',{directives:[{name:"test",rawName:"v-test:forwardButton",arg:"forwardButton"}],staticClass:"message__large-screen-only button--subtle button--small",attrs:{"button-text":_vm.forwardText,"title":_vm.forwardText,"icon":"forward"},on:{"click":_vm.forwardClicked}})],1),_c('div',{staticClass:"action-bar__group"},[_c('MoveToFolderModal',{attrs:{"busy-moving":_vm.isBusy.moving},on:{"moveToFolder":_vm.onMoveMessage}}),(_vm.isSpamMessage)?_c('ActionBarButton',{directives:[{name:"test",rawName:"v-test:markAsNotSpam",arg:"markAsNotSpam"},{name:"tooltip",rawName:"v-tooltip",value:({
                  position: 'bottom',
                  content: _vm.markAsNotSpamButtonText,
                }),expression:"{\n                  position: 'bottom',\n                  content: markAsNotSpamButtonText,\n                }"}],staticClass:"button--subtle button--small",attrs:{"icon":"not-spam"},on:{"click":_vm.markAsNotSpamClicked}}):_c('ActionBarButton',{directives:[{name:"test",rawName:"v-test:markAsSpam",arg:"markAsSpam"},{name:"tooltip",rawName:"v-tooltip",value:({
                  position: 'bottom',
                  content: _vm.markAsSpamButtonText,
                }),expression:"{\n                  position: 'bottom',\n                  content: markAsSpamButtonText,\n                }"}],staticClass:"button--subtle button--small",attrs:{"icon":"spam"},on:{"click":_vm.markAsSpamClicked}}),(_vm.activeFolder.type === 'trash')?_c('DeleteConfirmationModal',{attrs:{"ok-button-text":_vm.deleteMessageText,"warning-text":_vm.deleteWarningText},on:{"delete":_vm.onDeleteClicked},scopedSlots:_vm._u([{key:"DeleteButton",fn:function(deleteConfirmationModalScope){return [_c('ActionBarButton',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                      position: 'bottom',
                      content: _vm.deleteMessageText,
                    }),expression:"{\n                      position: 'bottom',\n                      content: deleteMessageText,\n                    }"}],staticClass:"button--subtle button--small",attrs:{"icon":"trash"},on:{"click":deleteConfirmationModalScope.toggle}})]}}],null,false,35839639)}):_c('ActionBarButton',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ position: 'bottom', content: _vm.moveToTrashText }),expression:"{ position: 'bottom', content: moveToTrashText }"}],staticClass:"button--subtle button--small",attrs:{"icon":"trash"},on:{"click":_vm.onTrashClicked}}),_c('ActionBarButton',{directives:[{name:"test",rawName:"v-test:markAsUnreadButton",arg:"markAsUnreadButton"},{name:"tooltip",rawName:"v-tooltip",value:({ position: 'bottom', content: _vm.markAsUnreadText }),expression:"{ position: 'bottom', content: markAsUnreadText }"}],staticClass:"button--subtle button--small",attrs:{"icon":"unread","disabled":_vm.isBusy.markingAsUnread},on:{"click":_vm.markAsUnreadClick}}),_c('ActionBarButton',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                  position: 'bottom',
                  content: _vm.toggleMessageFlaggedDisplayValue,
                }),expression:"{\n                  position: 'bottom',\n                  content: toggleMessageFlaggedDisplayValue,\n                }"}],staticClass:"button--subtle button--small",attrs:{"active":_vm.activeMessage.flagged,"icon":"flag"},on:{"click":_vm.toggleMessageFlaggedClicked}})],1),_c('div',{staticClass:"action-bar__group"},[_c('Dropdown',{directives:[{name:"test",rawName:"v-test:secondaryActionsDropdown",arg:"secondaryActionsDropdown"}],scopedSlots:_vm._u([{key:"button",fn:function(ref){
                var isOpen = ref.isOpen;
                var toggle = ref.toggle;
return [_c('ActionBarButton',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                      position: 'bottom',
                      content: _vm.moreText,
                      disabled: isOpen,
                    }),expression:"{\n                      position: 'bottom',\n                      content: moreText,\n                      disabled: isOpen,\n                    }"}],staticClass:"button--subtle button--small",attrs:{"active":isOpen,"icon":"menu-horizontal"},on:{"click":toggle}})]}},{key:"content",fn:function(ref){
                    var toggle = ref.toggle;
return [_c('ul',{staticClass:"link-list",on:{"click":toggle}},[_c('li',[_c('a',{directives:[{name:"test",rawName:"v-test:viewSourceButton",arg:"viewSourceButton"}],staticClass:"button button--subtle link-list__item",attrs:{"href":_vm.messageSourceUrl,"target":"_blank"}},[_c('translate',[_vm._v("View source")])],1)]),(_vm.showPrintButton)?_c('li',{staticClass:"message-wrapper__print-button"},[_c('button',{directives:[{name:"test",rawName:"v-test:printButton",arg:"printButton"}],staticClass:"button button--subtle link-list__item",attrs:{"type":"button"},on:{"click":_vm.onPrintClick}},[_c('translate',[_vm._v("Print")])],1)]):_vm._e(),_c('li',[_c('BlockSenderModal',{attrs:{"sender":_vm.activeMessage.from && _vm.activeMessage.from.email}})],1),_c('li',[_c('MessageReportingDialog',{on:{"submit":_vm.reportMessageConfirmed},scopedSlots:_vm._u([{key:"button",fn:function(ref){
                    var showModal = ref.showModal;
return [_c('button',{directives:[{name:"test",rawName:"v-test:report-message-button",arg:"report-message-button"}],staticClass:"button button--subtle link-list__item",attrs:{"type":"button"},on:{"click":showModal}},[_c('translate',[_vm._v("Report message")])],1)]}}],null,true)})],1)])]}}])})],1)]),_c('div',{staticClass:"action-bar__row-right message__large-screen-only"},[_c('div',{staticClass:"action-bar__group"},[(_vm.activeMessageIndex !== -1)?_c('div',{staticClass:"action-bar__group"},[_c('div',{staticClass:"action-bar__group"},[_c('ActionBarButton',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                      position: 'bottom',
                      content: _vm.previousMessageText,
                    }),expression:"{\n                      position: 'bottom',\n                      content: previousMessageText,\n                    }"},{name:"test",rawName:"v-test:previousMessageButton",arg:"previousMessageButton"}],attrs:{"title":_vm.previousMessageText,"icon":"arrow-left","disabled":!_vm.previousMessageId},on:{"click":function($event){return _vm.$root.$emit(
                        'readMessageInCurrentView',
                        _vm.previousMessageId
                      )}}}),_c('span',[_vm._v(_vm._s(_vm.activeMessageNumberInList)+" of "+_vm._s(_vm.paginationTotal))]),_c('ActionBarButton',{directives:[{name:"test",rawName:"v-test:nextMessageButton",arg:"nextMessageButton"},{name:"tooltip",rawName:"v-tooltip",value:({
                      position: 'bottom',
                      content: _vm.nextMessageText,
                    }),expression:"{\n                      position: 'bottom',\n                      content: nextMessageText,\n                    }"}],attrs:{"icon":"arrow-right","disabled":!_vm.nextMessageId},on:{"click":function($event){return _vm.$root.$emit('readMessageInCurrentView', _vm.nextMessageId)}}})],1)]):_vm._e()])])])]},proxy:true},{key:"top",fn:function(ref){
                    var messageDecryptionStatus = ref.messageDecryptionStatus;
return [(
            _vm.activeMessage.flags.is_encrypted || _vm.activeMessage.flags.is_signed
          )?_c('MessageStatus',{attrs:{"encrypted":_vm.activeMessage.flags.is_encrypted,"signed":_vm.activeMessage.flags.is_signed,"erroneous-signatures":_vm.erroneousSignatures,"message-decryption-status":messageDecryptionStatus,"pgp-keys":_vm.activeMessage.pgp && _vm.activeMessage.pgp.encryption.recipient_keys}}):_vm._e()]}}])})],1)],1):_c('p',{staticClass:"panel-user-message"},[_c('translate',[_vm._v("Message does not exist.")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }