


















































































  import Vue from 'vue';
  import { mapGetters } from 'vuex';
  import { MessageReportType } from '@/api';
  import { FEATURE_MESSAGE_REPORTING } from '@/lib/featureFlags';
  import Checkbox from '@/components/form/Checkbox.vue';
  import DefaultDialog from '@/components/DefaultDialog.vue';
  import LoadingButton from '@/components/LoadingButton.vue';
  import NotificationBlock from '@/components/NotificationBlock/NotificationBlock.vue';
  import SelectInput from '@/components/form/SelectInput.vue';
  import SupportCenterLink from '@/components/SupportCenterLink.vue';

  export default Vue.extend({
    components: {
      Checkbox,
      DefaultDialog,
      LoadingButton,
      NotificationBlock,
      SelectInput,
      SupportCenterLink,
    },
    data() {
      return {
        loading: false,
        MessageReportType,
        uid: (this as any)._uid.toString(),
        reportType: '',
      };
    },
    computed: {
      ...mapGetters('features', ['featureById']),
      confirmLabel(): string {
        return this.$pgettext(
          'report-message',
          'By proceeding, I authorize the StartMail team to access the contents of this message.'
        );
      },
      phishingExplanationHtml(): string {
        return this.$gettext(
          '<strong>Only use this to report phishing mails.</strong> For regular spam, please use the ‘Mark message as spam’ button instead.'
        );
      },
      reportTypeLabel(): string {
        return this.$pgettext('report-message-report-type', 'Report type');
      },
      reportTypePlaceholder(): string {
        return this.$pgettext(
          'report-message-report-type',
          'Select a report type'
        );
      },
      reportTypeOptions(this: any): Array<Object> {
        const options = [
          {
            name: this.$pgettext('report-message-report-type', 'Phishing'),
            value: MessageReportType.phishing,
          },
        ];
        const shareWithSupportEnabled = this.featureById(
          FEATURE_MESSAGE_REPORTING
        )?.enabled;
        if (shareWithSupportEnabled) {
          options.push({
            name: this.$pgettext(
              'report-message-report-type',
              'Other (share with support)'
            ),
            value: MessageReportType.support,
          });
        }
        return options;
      },
      submitButtonLabel(): string {
        switch (this.reportType) {
          case MessageReportType.phishing:
            return this.$pgettext(
              'report-message-submit-button',
              'Report as phishing'
            );
          case MessageReportType.support:
            return this.$pgettext(
              'report-message-submit-button',
              'Share with support'
            );
          default: // unreachable
            return this.$pgettext('report-message-submit-button', 'Report');
        }
      },
    },
    methods: {
      async submit(_event: Event, close: Function) {
        this.loading = true;
        try {
          // see https://github.com/vuejs/rfcs/issues/586
          await new Promise((resolve, reject) => {
            this.$emit('submit', {
              reportType: this.reportType,
              resolve,
              reject,
            });
          });
        } finally {
          this.loading = false;
          close();
        }
      },
    },
  });
